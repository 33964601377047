@import "global";

body {
  background-color: $color-aaf-white !important;
}

#wrapper {
  padding: 60px 0 0 $navWidth;
}

#header {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 61px;
  background-color: $color-aaf-white;
  -webkit-box-shadow: 0 0 5px transparentize($color-aaf-grey2, 0);
  -moz-box-shadow: 0 0 5px transparentize($color-aaf-grey2, 0);
  box-shadow: 0 0 5px transparentize($color-aaf-grey2, 0);
}

#logo {
  text-align: left;
  width: $navWidth;
  margin: 0;

  > a {
    display: block;
    padding: 10px 10px 0 10px;

    > img {
      width: auto;
      height: 45px;
    }
  }
}

#usermenu {
  text-align: right;
  cursor: pointer;

  > a {
    display: block;
    padding: 7px 10px 0 10px;

    > img {
      width: 45px;
      height: auto;
    }
  }
}

.medical-handbook-online {
  margin-top: 20px !important;
}

.support-request-icon {
  color: $color-aaf-blue;
}

.hompage-icon {
  color: $color-aaf-blue;
}

.gdpr-information {
  padding: 10px;
  margin-bottom: 20px;
  background-color: $color-aaf-grey3;
}

.browser-information {
  padding: 10px;
  margin-bottom: 20px;
  background-color: $color-aaf-grey3;
}

.important-information {
  padding: 10px;
  margin-bottom: 20px;
  background-color: $color-aaf-blue;
  color: $color-aaf-white;
}

.important-information-link, .important-information-link:active {
  color: $color-aaf-white !important;
}

#navigation {
  position: fixed;
  z-index: 90;
  left: 0;
  top: 60px;
  bottom: 0;
  width: $navWidth;
  padding: 30px 0 $navSmallSize 0;
  background-color: $color-aaf-grey2;
  -webkit-box-shadow: 0 0 5px transparentize($color-aaf-grey2, 0.5);
  -moz-box-shadow: 0 0 5px transparentize($color-aaf-grey2, 0.5);
  box-shadow: 0 0 5px transparentize($color-aaf-grey2, 0.5);

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    outline: none;
  }

  > ul {
    height: 100%;
    overflow-y: auto;

    > li {
      border-bottom: 1px dashed lighten($color-aaf-grey2, 3%);

      &:last-child {
        border: none;
      }

      > a, span {
        display: block;
        position: relative;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        overflow-x: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
        font-family: $roboto-condensed;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        color: $color-aaf-white;
        padding: 0 10px;
        -webkit-transition: background-color 200ms, color 200ms;
        -moz-transition: background-color 200ms, color 200ms;
        -o-transition: background-color 200ms, color 200ms;
        transition: background-color 200ms, color 200ms;

        > i.fa {
          margin-right: 5px;
        }
      }

      &.hasSub > span {
        padding-right: 25px;

        &:after {
          content: '>';
          display: inline-block;
          position: absolute;
          right: 13px;
          //top: 13px;
          //font: normal normal normal 14px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }

      &.opened {
        > a:after, span:after {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      &.opened > a,
      &:hover > a {
        background-color: $color-aaf-white;
        color: $color-aaf-grey2;
      }

      &.current > a {
        background-color: $color-aaf-blue;
        color: $color-aaf-white;
      }

      ul {
        display: none;
        -webkit-transition: background-color 300ms;
        -moz-transition: background-color 300ms;
        -o-transition: background-color 300ms;
        transition: background-color 300ms;

        li {
          a {
            position: relative;
            display: block;
            word-break: break-all;
            cursor: pointer;
            line-height: 1.1;
            font-family: $roboto-condensed;
            font-size: 14px;
            font-weight: 400;
            color: $color-aaf-white;
            padding: 8px 10px;
            border-left: 5px solid transparent;
            text-decoration: none;
          }

          &.current > a,
          &:hover > a {
            border-color: $color-aaf-blue;
            background-color: darken($color-aaf-grey2, 3%);
          }
        }
      }

      &.opened > ul {
        background-color: darken($color-aaf-grey2, 6%);
      }

    }
  }

}

#navigation-switch {
  position: absolute;
  display: block;
  height: $navSmallSize;
  width: $navSmallSize;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: $color-aaf-white;
  line-height: $navSmallSize;
  text-align: center;
  font-size: 16px;
  -webkit-transition: background 200ms;
  -moz-transition: background 200ms;
  -o-transition: background 200ms;
  transition: background 200ms;

  &:hover {
    background: lighten($color-aaf-grey2, 5%);
  }
}

body.smallNav {
  #wrapper {
    padding-left: $navSmallSize;
  }

  #navigation {
    width: $navSmallSize;

    > ul {
      overflow: visible;

      > li {
        position: relative;

        > a {
          height: $navSmallSize;
          padding: 0;
          width: $navSmallSize;
          line-height: $navSmallSize;
          word-break: normal;
          overflow: hidden;

          i {
            margin: 0;
            width: $navSmallSize;
            height: $navSmallSize;
            text-align: center;
            line-height: $navSmallSize;
          }

          &:after {
            display: none;
          }
        }

        > ul {
          position: absolute;
          display: none !important;
          width: $navWidth;
          left: $navSmallSize;
          background: $color-aaf-white;

          > li {
            > a {
              color: $color-aaf-grey2;
            }

            &.active > a,
            &:hover > a {
              color: $color-aaf-white;
            }
          }
        }

        &:hover {
          > a {
            width: $navWidth + $navSmallSize;
            overflow: visible;

            i {
              margin-right: 10px;
            }
          }

          > ul {
            display: block !important;
          }
        }
      }
    }
  }

  #navigation-switch {
    i:before {
      content: '\f054';
    }
  }
}

#content {
  position: relative;
  padding: 40px;
}

.table-overview > tbody > tr {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: gainsboro;
}

.table-overview > tbody > tr:last-of-type {
  border-bottom-style: none;
}

.highlight-research-obs-border {
  border: 2px solid rgb(100, 180, 255) !important;
}

.highlight-research-obs-color {
  color: rgb(50, 90, 125);
}

.pre {
  white-space: pre;
}

li .select2-search {
  margin-top: -.2rem;
}

.update-profile-info {
  margin: 3rem 0;
  color: white;
  background-color: $color-aaf-blue;
  padding: 10px;
}

.update-profile-info > a {
  color: white !important;
  font-weight: bold;
}

pre {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  color: #212529;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.legacy {
  color: $color-aaf-grey2 !important;
}

.hidden {
  display: none !important;
}

.btn-primary {
  color: white !important;
}

.float-end > form {
  float: right !important;
}

.seminar-image {
  max-width: 150px;
}