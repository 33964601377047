@import "global";

.color-success {
  color: $color-success;
}

.color-danger {
  color: $color-danger;
}

.color-waiting {
  color: $color-aaf-blue;
}

.btn-waiting {
  background-color: $color-aaf-blue !important;
  color: white !important;
}

.profile-photo {
  max-width: 300px;
  max-height: 500px;
}

.m-1px {
  margin: 1px !important;
}

.custom-file-label {
  color: #777777 !important;
}
.max-inline-size-110 {
  max-inline-size: 110px;
}
.max-inline-size-190 {
  max-inline-size: 190px;
}
.max-inline-size-400 {
  max-inline-size: 400px;
}
.max-inline-size-600 {
  max-inline-size: 600px;
}
.max-inline-size-800 {
  max-inline-size: 800px;
}