@import "global";

.label-col {
  font-weight: bold;
  padding-right: 10px;
  width: 180px;
}

.value-col {
  width: 420px;
  max-width: 420px;
  margin: 0 !important;
}

.seminar-information{
  font-weight: bold !important;
  color: red !important;
}

.key-value {
  padding-left: 15px;
  padding-right: 15px;
  width: 630px;
}

.cd-abbreviation {
  width: 40px;
}

.cd-value {
  max-width: 380px;
}

.fellow-seminar-image {
  max-width: 110px;
  max-height: 130px;
}

.fellow-apply-seminar-card {
  margin-right: 20px;
  width: 830px;
  min-width: 630px !important;
  max-width: 100%;
}

.apply-button {
  margin-top: 20px;
  min-width: 110px;
  width: 100%;
}

.background-color-positive {
  background-color: $color-positive !important;
}

.background-color-negative {
  background-color: $color-negative !important;
}

.background-color-pending {
  background-color: $color-pending !important;
}

.background-color-test-env {
  background-color: peachpuff !important;
}

.th-color-link {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
}