.hide-filter-field {
  display: none;
}

.fellow-booklet-card {
  min-height: 210px;
  //max-width: 600px;
  //width: 600px;
}

.fellow-booklet-photo {
  max-height: 190px;
}

@media print {
  h2 {
    margin-left: -72.5px !important;
  }
  #header, #navigation, #printButton, #backLink, .sf-toolbar {
    display: none;
  }

  .fellow-booklet-card {
    page-break-inside: avoid;
  }

  #toPrint {
    background-color: white;
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: -100px;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
  }
}