$color-aaf-blue: #488BB6;
$color-aaf-black: #222222;
$color-aaf-grey1: #666666;
$color-aaf-grey2: #939FA6;
$color-aaf-grey3: #dee2e4;
$color-aaf-white: #fcfcfc;
$color-aaf-red: #FF0000;
$color-positive: #dcffcd;
$color-negative: #f7e4e6;
$color-pending: #f2faff;
$color-success: #28a745;
$color-danger: #dc3545;

/* Bootstrap overrides */
$font-family-sans-serif: 'Roboto', sans-serif !important;
$info: $color-aaf-grey2;
$primary: $color-aaf-blue;
$secondary: $color-aaf-grey2;

$roboto-condensed: 'Roboto Condensed', sans-serif;

$navWidth: 235px;
$navSmallSize: 50px;
$wizardNavWidth: 300px;
$contentPadding: 40px;

$enable-grid-classes: false;

@import "~bootstrap/scss/bootstrap";
@import "~select2/dist/css/select2.min.css";
@import 'select2-bootstrap-5-theme.scss';
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '@fontsource/roboto';
@import '@fontsource/roboto-condensed';
