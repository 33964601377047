@import "global";

.required:after {
  color: $color-aaf-red;
  content: " *";
}

.legacy-data {
  color: $color-aaf-red;
}

.hr-container {
  margin-top: 1.5rem;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: $color-aaf-black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
}

.hr-text:before {
  content: '';
  background: $color-aaf-grey2;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  opacity: 1;
  content: attr(data-content);
  position: absolute;
  left: 0;
  display: inline-block;
  padding-right: .5em;
  line-height: 1.5em;
  color: $color-aaf-black;
  background-color: $color-aaf-white;
}

.show-pointer {
  cursor: pointer;
}

input.email {
  height: 0;
  margin: 0;
  text-decoration: none;
  border: none;
  position: absolute;
  left: -500%;
  top: -500%;
}
